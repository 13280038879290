import React, { useCallback, useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { useDispatch } from "react-redux";
import { updateProfileCompletion } from "../../store/slices/dashboard/StartupDashboardSlice";
import { useToastContext } from "../../context/ToastContext";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useUploadImage from "../../hooks/useUploadImage";
import useDisplayFiles from "../../hooks/useDisplayFiles";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { ImportImage } from "../../utils/importImage";
import { randomId } from "../../utils/randomId";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { BankAccountModal } from "../../components/ui/modals/bank/bankAccountModal";
import { DeleteModal } from "../../components/ui/modals/utility-modals/deleteModal";
import { CertificateModal } from "../../components/ui/modals/profile/certificateModal";
import { EditStartupProfile } from "../../components/ui/modals/profile/editStartupProfile";
import { EditBankAccountModal } from "../../components/ui/modals/bank/editBankAccountModal";
import { DescriptionModal } from "../../components/ui/modals/profile/DescriptionModal";
import { StartupStageModal } from "../../components/ui/modals/profile/startupStageModal";
import { capitalizeText } from "../../utils/capitalizeText";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import banner from "../../images/banner.svg";
import certifiedLogo from "../../images/certified.png";
import verifiedLogo from "../../images/verified.png";
import { CameraIcon, DeleteIcon, FileIcon, PenIcon } from "../../icons/icons";
import { formatInvestmentAmount } from "../../utils/formatInvestmentAmount";

const StartupProfile = () => {
  const startupDetailsApi = `${process.env.REACT_APP_API_URL}display-startup-profile`;
  const editStartupProfileApi = `${process.env.REACT_APP_API_URL}update-startup-profile`;
  const deleteBankDetailsApi = `${process.env.REACT_APP_API_URL}delete-bank-details`;
  const uploadDocsApi = `${process.env.REACT_APP_API_URL}upload-documents`;
  const uploadVideoApi = `${process.env.REACT_APP_API_URL}upload-video`;
  const uploadCertificates = `${process.env.REACT_APP_API_URL}upload-certificates`;
  const deleteFileApi = `${process.env.REACT_APP_API_URL}delete-file`;
  const updateStartupStageApi = `${process.env.REACT_APP_API_URL}update-startup-stage`;
  const updateStartupDescriptionApi = `${process.env.REACT_APP_API_URL}update-startup-description`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { updateProfile } = useUpdateProfile();
  const { uploadImage } = useUploadImage();
  const { displayFiles } = useDisplayFiles();
  const navigate = useNavigate();
  const ref = useRef(true);
  const { displayIndustries } = useDisplayIndustries();
  const dispatch = useDispatch();
  const toast = useToastContext();

  const [startupDetails, setStartupDetails] = useState([]);
  const [editData, setEditData] = useState({});
  const [bankDetails, setBankDetails] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [idea, setIdea] = useState("");
  const [open, setOpen] = useState(false);
  const [editBankModal, setEditBankModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCertificateModal, setOpenCertificateModal] = useState(false);
  const [type, setType] = useState("add");
  const [fileType, setFileType] = useState("");
  const [documentType, setDocumentType] = useState("mandatory");
  const [id, setId] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [stageModal, setStageModal] = useState(false);
  const [profileImg, setProfileImg] = useState("");
  const [singleBank, setSingleBank] = useState([]);
  const [files, setFiles] = useState({
    documents: [],
    msme: [],
    gst: [],
    incorporation: [],
    others: [],
    video: "",
  });

  const getStartupData = useCallback(async () => {
    fetchApi(
      {
        url: startupDetailsApi,
        method: "POST",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setStartupDetails(data.startup_profile);
        setEditData(...data.startup_profile);
        setProfileImg(data.startup_profile[0].logo);
        setIdea(data.startup_profile[0].description);
        setBankDetails(data.bank_details);
        dispatch(
          updateProfileCompletion(data.startup_profile[0].profile_completion)
        );
      }
    );
  }, [startupDetailsApi, token, fetchApi, dispatch]);

  const postFilesApi = useCallback(
    (data) => {
      setFiles({
        documents: data.documents,
        msme: data.msme,
        gst: data.gst,
        incorporation: data.incorporation,
        others: data.others,
        video: data.video,
      });
      getStartupData();
    },
    [getStartupData]
  );

  const addDocument = (newFile) => {
    const formData = new FormData();
    formData.append("pdf[]", newFile);
    axios
      .post(uploadDocsApi, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        toast.open("true", "Document uploaded successfully!");
        displayFiles(postFilesApi);
      })
      .catch(() => toast.open("false", "Failed to upload document"));
  };

  const addVideo = (newFile) => {
    const formData = new FormData();
    formData.append("video", newFile);
    axios
      .post(uploadVideoApi, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        toast.open("true", "Video uploaded successfully!");
        displayFiles(postFilesApi);
      })
      .catch(() => toast.open("false", "Failed to upload video"));
  };

  const addCertificates = (file, document_name) => {
    const formData = new FormData();
    formData.append("files[]", file);
    formData.append("document_name", document_name);
    axios
      .post(uploadCertificates, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        toast.open("true", "Certificate uploaded successfully!");
        displayFiles(postFilesApi);
      })
      .catch(() => toast.open("false", "Failed to upload certificate"));
  };

  const deleteFile = useCallback(
    (type, id) => {
      fetchApi(
        {
          url: deleteFileApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            type: type,
            id: id,
          },
        },
        () => displayFiles(postFilesApi)
      );
    },
    [fetchApi, token, deleteFileApi, displayFiles, postFilesApi]
  );

  const deleteBankDetails = useCallback(
    (id) => {
      fetchApi(
        {
          url: deleteBankDetailsApi,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            id: id,
          },
        },
        () => getStartupData()
      );
    },
    [fetchApi, token, deleteBankDetailsApi, getStartupData]
  );

  const editStartupDetails = (data, industry) => {
    let dataObj = {
      name: data.startupName,
      phone: data.startupPhone,
      company_name: data.companyName,
      require_investment: data.requireInvestment,
      website: data.companyWebsite,
      business_start_date: data.startingDate,
      no_of_employees: data.noofemployees,
      linked_in: data.startupLinkedin,
    };
    if (industry.length > 0) {
      dataObj = { ...dataObj, startup_industry: industry };
    }
    updateProfile(editStartupProfileApi, dataObj, getStartupData, "startup");
  };

  const editStartupStage = useCallback(
    (data, previous_investment) => {
      fetchApi(
        {
          url: updateStartupStageApi,
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: {
            startup_stage: data.startupStage,
            previous_investment: previous_investment,
            investment_amount:
              previous_investment === "yes" ? data.prevInvestedAmount : "",
          },
        },
        (data) => {
          if (data.message === "validation error") {
            toast.open("warning", "Failed to submit. Validation failed");
            return;
          }
          navigate("/startup-profile");
          getStartupData();
        }
      );
    },
    [fetchApi, token, navigate, getStartupData, updateStartupStageApi, toast]
  );

  useEffect(() => {
    if (userRole !== "f@!3A") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getStartupData();
      displayFiles(postFilesApi);
      displayIndustries((data) => setIndustries(data.industries), "other");
    }
    return () => {
      ref.current = false;
    };
  }, [
    getStartupData,
    displayFiles,
    navigate,
    userRole,
    postFilesApi,
    displayIndustries,
  ]);

  const handleModal = (type, id, data) => {
    setType(type);
    if (type === "add") {
      setOpen(true);
    } else if (type === "edit") {
      setId(id);
      setSingleBank(data);
      setEditBankModal(true);
    } else {
      setId("");
    }
  };

  const handleDeleteModal = (id, type, fileType) => {
    setType(type);
    setFileType(fileType);
    setId(id);
    setOpenDeleteModal(true);
  };

  const handleCeritificateModal = (type) => {
    setDocumentType(type);
    setOpenCertificateModal(true);
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="md:p-5">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <>
              <div
                className="relative hidden md:block bg-cover h-[250px]"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              ></div>
              <div className="bg-white shadow-md md:rounded-md">
                <div className="grid grid-cols-1 md:grid-cols-5 px-3 gap-x-1">
                  <div className="ml-auto md:hidden">
                    <button
                      type="button"
                      className="profile-btns bg-primary text-white mt-2 mx-1 text-center px-2 py-1 rounded-md text-xs md:text-sm"
                      onClick={() => setProfileModal(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="relative flex flex-col md:flex-row items-center justify-center">
                    <div>
                      <img
                        src={profileImg.length > 0 ? profileImg : FavIcon}
                        className="profile-img shadow"
                        alt="profile-img"
                      />
                      <div className="img-icon bg-primary p-1">
                        <label
                          htmlFor="upload-logo"
                          className="mb-0 cursor-pointer"
                        >
                          <CameraIcon fill="#fff" />
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          id="upload-logo"
                          hidden
                          onChange={(e) =>
                            uploadImage(e.target.files[0], getStartupData)
                          }
                        />
                      </div>
                    </div>
                    {profileImg.length < 1 && (
                      <p className="text-red-500 font-medium">Upload image</p>
                    )}
                    <span className="flex items-center ml-6 mt-3 md:mt-0 font-medium capitalize">
                      {startupDetails[0]?.country.length > 0 && (
                        <>
                          <span className="pe-1 capitalize">
                            {startupDetails[0]?.city}
                          </span>
                          <img
                            src={
                              startupDetails[0]?.country_code
                                ? `https://flagsapi.com/${startupDetails[0]?.country_code}/flat/64.png`
                                : "https://flagsapi.com/IN/flat/64.png"
                            }
                            alt={`${startupDetails[0]?.country} flag`}
                            width={30}
                            title={capitalizeText(startupDetails[0]?.country)}
                            loading="lazy"
                          />
                        </>
                      )}
                    </span>
                  </div>
                  <div className="relative md:col-span-4 pb-4 mb-5 md:pl-[3rem] 2xl:pl-0">
                    <button
                      type="button"
                      className="hidden md:block profile-btns bg-primary text-white mt-2 text-center px-2 py-1 rounded-md absolute right-0 md:top-unset md:bottom-2.5 text-xs md:text-sm"
                      onClick={() => setProfileModal(true)}
                    >
                      Edit
                    </button>
                    {startupDetails &&
                      startupDetails.map((startup) => (
                        <div
                          key={startup.user_id}
                          className="grid grid-cols-2 gap-2 md:gap-1 mb-5 pt-3 mt-5 md:mt-0"
                        >
                          <p className="col-span-2 md:col-span-1 font-semibold flex items-center">
                            Name :{" "}
                            <span className="ms-1 flex items-center font-medium uppercase">
                              {startup.name}
                              {startupDetails.length > 0 && (
                                <span className="flex items-center">
                                  {startupDetails[0].platform_verify ===
                                    "1" && (
                                    <img
                                      src={verifiedLogo}
                                      alt="PitchHere verification logo"
                                      width={25}
                                      title="Verified by PitchHere"
                                      className="ms-1"
                                    />
                                  )}
                                  {startupDetails[0].verify_profile === "1" && (
                                    <img
                                      src={certifiedLogo}
                                      alt="Mentor certification logo"
                                      width={20}
                                      title="Certified by Mentor"
                                      className="ms-1"
                                    />
                                  )}
                                </span>
                              )}
                            </span>
                          </p>
                          <p className="col-span-2 md:col-span-1 font-semibold">
                            Email :{" "}
                            <span className="font-medium">{startup.email}</span>
                          </p>
                          <p className="col-span-2 md:col-span-1 font-semibold">
                            Company :{" "}
                            <span className="font-medium capitalize">
                              {startup.company_name}
                            </span>
                          </p>
                          <p className="col-span-2 md:col-span-1 font-semibold">
                            Desired Investment :{" "}
                            <span
                              className="font-medium"
                              title={`Rs. ${startup.require_investment}`}
                            >
                              ₹{" "}
                              {formatInvestmentAmount(
                                startup.require_investment
                              )}
                            </span>
                          </p>
                          <p className="col-span-2 md:col-span-1 font-semibold">
                            Website :{" "}
                            <span className="font-medium">
                              {startup.website.length > 0 ? (
                                <a
                                  href={startup.website}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-blue-500"
                                >
                                  URL
                                </a>
                              ) : (
                                <span className="text-red-500 text-sm">
                                  Missing
                                </span>
                              )}
                            </span>
                          </p>
                          <p className="col-span-2 md:col-span-1 font-semibold">
                            LinkedIn :{" "}
                            <span className="font-medium">
                              {startup.linked_in.length > 0 ? (
                                <a
                                  href={startup.linked_in}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-blue-500"
                                >
                                  Profile
                                </a>
                              ) : (
                                <span className="text-red-500 text-sm">
                                  Missing
                                </span>
                              )}
                            </span>
                          </p>
                          <p className="col-span-2 font-semibold">
                            Industries :{" "}
                            <span className="font-medium ms-1">
                              {startup.startup_industry.map((industry, i) => (
                                <span className="outline-tags" key={i}>
                                  {industry.replace(new RegExp("_", "g"), " ")}
                                </span>
                              ))}
                            </span>
                          </p>
                          <p className="mt-3 col-span-2 font-semibold under underline underline-offset-2 text-xs">
                            Note : How to update your profile.{" "}
                            <a
                              href="https://www.youtube.com/watch?v=2IFhheMdwCs"
                              target="_blank"
                              rel="noreferrer"
                              className="text-blue-500"
                            >
                              Watch here.
                            </a>
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 mt-2 gap-x-3">
                <div className="ideaContainer p-3 bg-white shadow-md rounded-md relative h-full">
                  <div className="flex items-center justify-between mb-3">
                    <h5 className="font-semibold">Description</h5>
                    <p
                      className=" text-customOrange cursor-pointer"
                      type="button"
                      onClick={() => setDescriptionModal(true)}
                    >
                      Write here
                    </p>
                  </div>
                  {idea ? (
                    <p className="text-sm">{idea}</p>
                  ) : (
                    <p>Add short description about your company and work</p>
                  )}
                </div>
                <div className="more-info-container bg-white shadow-md rounded-md p-3">
                  <div className="flex items-center justify-between mb-3">
                    <h5 className="font-semibold">More Information</h5>
                    <span
                      className="text-customOrange cursor-pointer"
                      onClick={() => setStageModal(true)}
                    >
                      Edit
                    </span>
                  </div>
                  {startupDetails &&
                    startupDetails.map((startup) => (
                      <div className="grid grid-cols-2" key={startup.user_id}>
                        <div className="flex flex-col items-start mb-1">
                          <p className="mb-0 font-bold text-xs">
                            Startup Stage
                          </p>
                          <p className="text-xs capitalize">
                            {startup.startup_stage.replace("-", " ") || "N/A"}
                          </p>
                        </div>
                        <div className="flex flex-col items-start mb-1">
                          <p className="mb-0 font-bold text-xs">
                            Previous Investment
                          </p>
                          <p className="text-xs capitalize">
                            {startup.previous_investment || "N/A"}
                          </p>
                        </div>
                        <div className="flex flex-col items-start mb-1">
                          <p className="mb-0 font-bold text-xs">
                            Invested Amount
                          </p>
                          <p className="text-xs">
                            {startup.investment_amount || "N/A"}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 mt-2 gap-3">
                <div className="documentsContainer bg-white shadow-md rounded-md p-3 relative">
                  <div className="flex items-center justify-between mb-3">
                    <h5 className="font-semibold">Pitch Deck (pdf*)</h5>
                    <label
                      className="text-customOrange cursor-pointer mb-0 text-sm"
                      htmlFor="startup-documents"
                    >
                      Upload documents
                    </label>
                    <input
                      type="file"
                      id="startup-documents"
                      accept="application/pdf,.pdf"
                      onChange={(e) => addDocument(e.target.files[0])}
                      hidden
                    />
                  </div>
                  {files.documents.length > 0 ? (
                    <div className="grid grid-cols-1">
                      {files.documents.map((document) => (
                        <div
                          key={document._id}
                          className="profile-detail-card relative"
                        >
                          <Link
                            to={document.path}
                            target="_blank"
                            rel="noreferrer"
                            id="documents"
                            className="flex justify-between items-center px-4 py-2 mt-2 text-sm rounded-md bg-gray-200 hover:opacity-50"
                          >
                            <span className="font-medium">
                              {document.file_name}
                            </span>
                            <FileIcon />
                          </Link>
                          <DeleteIcon
                            className="delete-account-btn absolute right-[5%] top-0 z-5 cursor-pointer"
                            onClick={() =>
                              handleDeleteModal(
                                document._id,
                                "file",
                                "document"
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No documents found, upload PDF.</p>
                  )}
                </div>
                <div className="certificatesContainer bg-white shadow-md rounded-md p-3 relative">
                  <div className="flex items-center justify-between mb-3">
                    <h5 className="font-semibold">Certificates</h5>
                    <span
                      onClick={() => handleCeritificateModal("mandatory")}
                      className="text-customOrange cursor-pointer mb-0 text-sm"
                    >
                      Upload certificates
                    </span>
                  </div>
                  <div className="profile-detail-card relative">
                    <Link
                      to={
                        files.incorporation.length > 0
                          ? files.incorporation[0].path
                          : "#"
                      }
                      target={
                        files.incorporation.length > 0 ? "_blank" : "_self"
                      }
                      rel="noopener noreferrer"
                      className={`flex justify-between items-center px-4 py-2 mt-2 text-xs md:text-sm rounded-md bg-gray-200 ${
                        files.incorporation.length > 0 && "hover:opacity-50"
                      }`}
                    >
                      <span className="font-medium">
                        Certificate of Incorporation
                      </span>
                      <span
                        className={
                          files.incorporation.length > 0
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        {files.incorporation.length > 0 ? "Added" : "Missing"}
                      </span>
                    </Link>
                    {files.incorporation.length > 0 && (
                      <DeleteIcon
                        className="delete-account-btn absolute right-0 top-0 cursor-pointer"
                        onClick={() =>
                          handleDeleteModal(
                            files.incorporation[0]._id,
                            "file",
                            "incorporation"
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="profile-detail-card relative">
                    <Link
                      to={files.msme.length > 0 ? files.msme[0].path : "#"}
                      target={files.msme.length > 0 ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                      className={`flex justify-between items-center px-4 py-2 mt-2 text-xs md:text-sm rounded-md bg-gray-200 ${
                        files.msme.length > 0 && "hover:opacity-50"
                      }`}
                    >
                      <span className="font-medium">MSME Registration</span>
                      <span
                        className={
                          files.msme.length > 0
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        {files.msme.length > 0 ? "Added" : "Missing"}
                      </span>
                    </Link>
                    {files.msme.length > 0 && (
                      <DeleteIcon
                        className="delete-account-btn absolute right-0 top-0 cursor-pointer"
                        onClick={() =>
                          handleDeleteModal(files.msme[0]._id, "file", "msme")
                        }
                      />
                    )}
                  </div>
                  <div className="profile-detail-card relative">
                    <Link
                      to={files.gst.length > 0 ? files.gst[0].path : "#"}
                      target={files.gst.length > 0 ? "_blank" : "_self"}
                      rel="noopener noreferrer"
                      className={`flex justify-between items-center px-4 py-2 mt-2 text-xs md:text-sm rounded-md bg-gray-200 ${
                        files.gst.length > 0 && "hover:opacity-50"
                      }`}
                    >
                      <span className="font-medium">GST Registration</span>
                      <span
                        className={
                          files.gst.length > 0
                            ? "text-green-500"
                            : "text-red-500"
                        }
                      >
                        {files.gst.length > 0 ? "Added" : "Missing"}
                      </span>
                    </Link>
                    {files.gst.length > 0 && (
                      <DeleteIcon
                        className="delete-account-btn absolute right-0 top-0 cursor-pointer"
                        onClick={() =>
                          handleDeleteModal(files.gst[0]._id, "file", "gst")
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="otherDocumentsContainer bg-white shadow-md rounded-md p-3 relative">
                  <div className="flex items-center justify-between mb-3">
                    <h5 className="font-semibold">Other Documents</h5>
                    <span
                      onClick={() => handleCeritificateModal("other")}
                      className="text-customOrange cursor-pointer mb-0 text-sm"
                    >
                      Upload documents
                    </span>
                  </div>
                  {files.others.length > 0 ? (
                    <div className="grid grid-cols-1">
                      {files.others.map((file) => (
                        <div
                          key={file._id}
                          className="profile-detail-card relative"
                        >
                          <Link
                            to={file.path}
                            target="_blank"
                            rel="noopener noreferrer"
                            id="file"
                            className="flex justify-between items-center px-4 py-2 mt-2 text-xs md:text-sm rounded-md bg-gray-200 hover:opacity-50"
                          >
                            <span className="font-medium">
                              {file.document_name}
                            </span>
                            <FileIcon />
                          </Link>
                          <DeleteIcon
                            className="delete-account-btn absolute right-[5%] top-0 z-5 cursor-pointer"
                            onClick={() =>
                              handleDeleteModal(file._id, "file", "other")
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No certificates found.</p>
                  )}
                </div>
                <div className="bankDetailsContainer bg-white shadow-md rounded-md p-3 relative">
                  <div className="flex items-center justify-between mb-3">
                    <h5 className="font-semibold">Bank Details</h5>
                    {bankDetails && bankDetails.length < 1 && (
                      <p
                        className="text-customOrange cursor-pointer"
                        onClick={() => handleModal("add")}
                        type="button"
                      >
                        Add new
                      </p>
                    )}
                  </div>
                  <div className="grid grid-cols-1 mt-3" id="bank-details">
                    {bankDetails && bankDetails.length > 0 ? (
                      bankDetails.map((bank) => (
                        <div
                          className="profile-detail-card relative"
                          key={bank.id}
                        >
                          <div className="flex justify-between items-center px-4 py-2 mt-2 rounded-md bg-gray-200 hover:opacity-50">
                            <div>
                              <p className="font-semibold text-xs md:text-sm  leading-relaxed">
                                Account Holder:
                                <span className="ps-2 font-medium whitespace-normal">
                                  {bank.name}
                                </span>
                              </p>
                              <p className="font-semibold text-xs md:text-sm leading-relaxed">
                                Account Number:
                                <span className="ps-2 font-medium whitespace-normal">
                                  {bank.account_number}
                                </span>
                              </p>
                              <p className="font-semibold text-xs md:text-sm leading-relaxed">
                                IFSC Code:
                                <span className="ps-2 font-medium whitespace-normal">
                                  {bank.ifsc}
                                </span>
                              </p>
                            </div>
                            <ImportImage
                              imageName={bank.bank_name}
                              width={45}
                              alt={bank.bank_name}
                            />
                          </div>
                          <DeleteIcon
                            className="delete-account-btn absolute right-0 top-0 cursor-pointer"
                            onClick={() => handleDeleteModal(bank.id, "bank")}
                          />
                          <PenIcon
                            fill="#22C55E"
                            className="edit-account-details absolute right-[10%] top-0 cursor-pointer"
                            onClick={() => handleModal("edit", bank.id, bank)}
                          />
                        </div>
                      ))
                    ) : (
                      <p id="noBankDetails">No Bank Account found.</p>
                    )}
                  </div>
                </div>
                <div className="videoContainer bg-white shadow-md rounded-md p-3 relative">
                  <div className="flex items-center justify-between mb-3">
                    <h5 className="font-semibold">Video</h5>
                    <label
                      className="text-customOrange cursor-pointer mb-0 text-sm"
                      htmlFor="startup-video"
                    >
                      Upload video
                    </label>
                    <input
                      type="file"
                      id="startup-video"
                      accept="video/mp4,video/x-m4v,video/*"
                      hidden
                      onChange={(e) => addVideo(e.target.files[0])}
                    />
                  </div>
                  {files.video.length > 0 ? (
                    <div className="grid grid-cols-1 mt-3">
                      <div className="mt-4">
                        <video
                          controls
                          className="w-full h-[300px]"
                          id="videoFile"
                          src={files.video}
                        ></video>
                      </div>
                    </div>
                  ) : (
                    <p>No Video found.</p>
                  )}
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <BankAccountModal
        open={open}
        onClose={() => setOpen(false)}
        callBackFn={getStartupData}
      />
      <EditBankAccountModal
        key={id}
        open={editBankModal}
        onClose={() => setEditBankModal(false)}
        singleBank={singleBank}
        bankId={id}
        callBackFn={getStartupData}
      />
      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        deleteFile={deleteFile}
        deleteBankDetails={deleteBankDetails}
        type={type}
        fileType={fileType}
        id={id}
      />
      <CertificateModal
        open={openCertificateModal}
        onClose={() => setOpenCertificateModal(false)}
        addCertificates={addCertificates}
        documentType={documentType}
      />
      <EditStartupProfile
        key={randomId()}
        open={profileModal}
        onClose={() => setProfileModal(false)}
        editStartupDetails={editStartupDetails}
        editData={editData}
        industries={industries}
      />
      <DescriptionModal
        key={new Date()}
        open={descriptionModal}
        onClose={() => setDescriptionModal(false)}
        callBackFn={() => getStartupData()}
        url={updateStartupDescriptionApi}
        data={idea}
      />
      <StartupStageModal
        key={randomId()}
        open={stageModal}
        onClose={() => setStageModal(false)}
        editStartupStage={editStartupStage}
        editData={editData}
      />
    </div>
  );
};

export default StartupProfile;
