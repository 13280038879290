import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCredits } from "../../store/slices/FetchCreditsSlice";
import { updateTokensUsed } from "../../store/slices/dashboard/StartupDashboardSlice";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useNotificationApi from "../../hooks/useNotificationApi";
import { useToastContext } from "../../context/ToastContext";
import { randomId } from "../../utils/randomId";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import { Button } from "../../components/ui/Button";
import MeetingsTable from "./meetingsTable";
import { Error } from "../../components/ui/Error";
import { Loader } from "../../components/ui/Loader";
import { MeetingSchedule } from "../../components/ui/modals/meetings/meetingSchedule";
import { EditMeeting } from "../../components/ui/modals/meetings/editMeeting";
import ViewSummary from "../../components/ui/modals/meetings/viewSummary";
import { Dropdown } from "../../components/ui/inputs/dropdown";
import { ViewMeetingDetails } from "../../components/ui/modals/meetings/viewMeetingDetails";
import { getCurrentDate } from "../../utils/getCurrentDate";
import { ConfirmationModal } from "../../components/ui/modals/utility-modals/confirmationModal";

const Meetings = () => {
  const connectionsListApi = `${process.env.REACT_APP_API_URL}display-connections`;
  const scheduleMeetingApi = `${process.env.REACT_APP_API_URL}schedule-meeting`;
  const displayMeetingApi = `${process.env.REACT_APP_API_URL}display-meetings`;
  const cancelMeetingApi = `${process.env.REACT_APP_API_URL}cancel-meeting`;
  const editMeetingApi = `${process.env.REACT_APP_API_URL}edit-meeting`;
  const acceptorRejectMeetingApi = `${process.env.REACT_APP_API_URL}accept-reject`;
  const displaySummaryApi = `${process.env.REACT_APP_API_URL}display-summary`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole, name } = useLocalStorage();
  const navigate = useNavigate();
  const toast = useToastContext();
  const { sendNotification } = useNotificationApi();
  const dispatch = useDispatch();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [open, setOpen] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [connections, setConnections] = useState([]);
  const [userId, setUserId] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [summaryModal, setSummaryModal] = useState(false);
  const [singleMeeting, setSingleMeeting] = useState({});
  const [summary, setSummary] = useState([]);
  const [tableType, setTableType] = useState("All");
  const [page, setPage] = useState(1);
  const [viewMeetingDetail, setViewMeetingDetail] = useState({
    open: false,
    data: [],
  });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    callbackFn: null,
    message: "",
  });

  const connectionsList = useCallback(() => {
    fetchApi(
      {
        url: connectionsListApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setConnections(data.response);
      }
    );
  }, [connectionsListApi, token, fetchApi]);

  const displayMeeting = useCallback(() => {
    fetchApi(
      {
        url: displayMeetingApi,
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        setMeetings(data.details);
        setUserId(data.user_id);
      }
    );
  }, [displayMeetingApi, token, fetchApi]);

  useEffect(() => {
    const timer = setTimeout(() => {
      displayMeeting();
      connectionsList();
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [connectionsList, displayMeeting]);

  const scheduleMeeting = useCallback(
    (type, data, recieverId) => {
      let dataObj = {
        time: data.hour + ":" + data.minutes,
        title: data.title,
        date: getCurrentDate(),
        roleType: 1,
      };
      if (type === "other") {
        dataObj = { ...dataObj, reciever_id: data.user };
      } else {
        dataObj = { ...dataObj, reciever_id: recieverId };
      }
      fetchApi(
        {
          url: scheduleMeetingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: dataObj,
        },
        (response) => {
          if (response.message === "don't have wallet point") {
            toast.open("warning", "Insufficient tokens");
            navigate("/store");
            return;
          }
          toast.open("true", "Meeting scheduled successfully!");
          sendNotification(
            type === "other" ? data.user : recieverId,
            `Incoming video meeting request from ${name}`,
            "meeting"
          );
          if (userRole === "f@!3A") {
            dispatch(
              updateCredits({ type: "remove", amount: data.meeting_fee })
            );
            dispatch(
              updateTokensUsed({ type: "add", amount: data.meeting_fee })
            );
          }
          displayMeeting();
        }
      );
    },
    [
      displayMeeting,
      scheduleMeetingApi,
      fetchApi,
      token,
      navigate,
      toast,
      sendNotification,
      name,
      dispatch,
      userRole,
    ]
  );

  const acceptorRejectMeeting = useCallback(
    (meetingId, status, connection_id, senderRole, recieverRole) => {
      fetchApi(
        {
          url: acceptorRejectMeetingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: meetingId,
            type: status,
            mentor_role:
              senderRole === "R$7s2"
                ? "sender"
                : recieverRole === "R$7s2"
                ? "reciever"
                : "investor",
          },
        },
        (data) => {
          if (data.message === "delete") {
            toast.open("warning", "Meeting cancelled by startup.");
          }
          sendNotification(
            connection_id,
            `Meeting request accepted by ${name}`,
            "meeting"
          );
          displayMeeting();
        }
      );
    },
    [
      displayMeeting,
      acceptorRejectMeetingApi,
      fetchApi,
      token,
      toast,
      name,
      sendNotification,
    ]
  );

  const cancelMeeting = useCallback(
    (meetingId, connection_id) => {
      fetchApi(
        {
          url: cancelMeetingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: meetingId,
          },
        },
        (data) => {
          if (data.message === "accepted") {
            toast.open("warning", "Meeting response received. Check status.");
          }
          sendNotification(
            connection_id,
            `Meeting request denied by ${name}`,
            "meeting"
          );
          if (userRole === "f@!3A") {
            dispatch(updateCredits({ type: "add", amount: data.meeting_fee }));
            dispatch(
              updateTokensUsed({ type: "remove", amount: data.meeting_fee })
            );
          }
          displayMeeting();
        }
      );
    },
    [
      displayMeeting,
      cancelMeetingApi,
      fetchApi,
      token,
      toast,
      name,
      sendNotification,
      dispatch,
      userRole,
    ]
  );

  const editMeeting = useCallback(
    (data, id) => {
      fetchApi(
        {
          url: editMeetingApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: id,
            time: data.hour + ":" + data.minutes,
            title: data.title,
            date: getCurrentDate(),
          },
        },
        (data) => {
          if (data.message === "accepted") {
            toast.open("warning", "Meeting response received. Check status.");
          } else {
            toast.open("true", "Meeting details updated");
          }
          displayMeeting();
        }
      );
    },
    [displayMeeting, editMeetingApi, fetchApi, token, toast]
  );

  const displaySummary = useCallback(
    (meetingId) => {
      fetchApi(
        {
          url: displaySummaryApi,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "x-token": token,
          },
          body: {
            id: meetingId,
          },
        },
        (data) => {
          setSummary(data.summary);
        }
      );
    },
    [displaySummaryApi, fetchApi, token]
  );

  const handleModals = (type, data) => {
    if (type === "meeting") {
      setSingleMeeting(data);
      setOpenEdit(true);
    } else if (type === "summary") {
      displaySummary(data);
      setSummaryModal(true);
    }
  };

  const changeTableType = (value) => {
    setTableType(value);
    setPage(1);
  };

  const handleConfirmationModal = (
    meetingId,
    status,
    connection_id,
    senderRole,
    recieverRole
  ) => {
    setConfirmationModal(true);
    if (status === "reject") {
      setConfirmModalData({
        callbackFn: () =>
          acceptorRejectMeeting(
            meetingId,
            status,
            connection_id,
            senderRole,
            recieverRole
          ),
        message: "Are you sure you want to reject this meeting request.",
      });
    } else if (status === "accept") {
      setConfirmModalData({
        callbackFn: () =>
          acceptorRejectMeeting(
            meetingId,
            status,
            connection_id,
            senderRole,
            recieverRole
          ),
        message: "Are you sure you want to accept this meeting request.",
      });
    } else if (status === "cancel") {
      setConfirmModalData({
        callbackFn: () => cancelMeeting(meetingId, connection_id),
        message: "Are you sure you want to cancel this meeting request.",
      });
    }
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-4">
          {error && <Error />}
          {isLoading && <Loader />}
          {!error && !isLoading && (
            <>
              <div className="flex items-center justify-between mb-4">
                <h5 className="text-lg font-medium">Meetings</h5>
                {userRole !== "R$7s2" && userRole !== "V&iR8" && (
                  <Button className="p-2" onClick={() => setOpen(true)}>
                    Schedule New Meeting
                  </Button>
                )}
              </div>
              <div className="grid grid-cols-3">
                <div className="col-span-3 lg:col-span-2 font-medium flex flex-col items-start text-xs mb-5">
                  <span className="text-sm">Note:</span>
                  <div className="text-xs space-y-1">
                    <p>
                      1. Meetings will expire automatically 1 hour post
                      Schedule(Meeting) Time.
                    </p>
                    {userRole === "R$7s2" && (
                      <p>
                        2. It is mandatory for mentors to submit a summary.
                        Amount will only be transfered to your account if
                        summary has been added.
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-3 lg:col-span-1 flex items-center mb-2 lg:ml-auto">
                  <label
                    htmlFor="meeting-filter"
                    className="text-lg font-medium mr-2 mb-0"
                  >
                    Filter :{" "}
                  </label>
                  <Dropdown
                    options={[
                      { label: "All", value: "All" },
                      { label: "Accepted", value: "Accepted" },
                      { label: "Declined", value: "Declined" },
                      { label: "Pending", value: "Pending" },
                      { label: "Add Summary", value: "Add Summary" },
                    ]}
                    onChange={(selectedOption) =>
                      changeTableType(selectedOption.value)
                    }
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        width: "200px",
                      }),
                    }}
                    defaultValue={{ label: "All", value: "All" }}
                    id="meeting-filter"
                  />
                </div>
              </div>
              <MeetingsTable
                data={meetings}
                userId={userId}
                handleModals={handleModals}
                page={page}
                setPage={setPage}
                tableType={tableType}
                userRole={userRole}
                setViewMeetingDetail={setViewMeetingDetail}
                handleConfirmationModal={handleConfirmationModal}
              />
            </>
          )}
        </div>
      </div>
      <MeetingSchedule
        key={randomId()}
        open={open}
        onClose={() => setOpen(false)}
        data={connections}
        scheduleMeeting={scheduleMeeting}
      />
      <EditMeeting
        key={randomId()}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        meetingData={singleMeeting}
        userId={userId}
        editMeeting={editMeeting}
      />
      <ViewSummary
        key={randomId()}
        open={summaryModal}
        onClose={() => setSummaryModal(false)}
        data={summary}
        userId={userId}
      />
      <ViewMeetingDetails
        open={viewMeetingDetail.open}
        onClose={() =>
          setViewMeetingDetail((prev) => ({ ...prev, open: false }))
        }
        meetingData={viewMeetingDetail.data}
        userId={userId}
      />
      <ConfirmationModal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        callBackFn={confirmModalData.callbackFn}
        message={confirmModalData.message}
      />
    </div>
  );
};

export default Meetings;
