import React, {
  useState,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/layout/Navbar";
import "./dashboard.css";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/Error";
import { BarGraph } from "../../components/ui/graphs-charts/barGraph";
import LongCard from "../../components/ui/cards/longCard";
import WelcomeCard from "../../components/ui/cards/welcomeCard";
import AmountCard from "../../components/ui/cards/amountCard";
import RecentInvestments from "./recentInvestment";
import rupeeIcon from "../../images/rupee-icon.svg";
import usersIcon from "../../images/users-icon.svg";
import addUserIcon from "../../images/add-user-icon.svg";
import RecentStartups from "./recentStartups";
import {
  AngleRightIcon,
  LongRightArrow,
  RocketIcon,
  ShoppingCartIcon,
  UserGraduateIcon,
  UserTieIcon,
} from "../../icons/icons";
import Carousel from "../../components/ui/Carousel";

const InvestorDashBoard = () => {
  const investorDashboardApi = `${process.env.REACT_APP_API_URL}investor-dashboard`;
  const { isLoading, error, fetchApi } = useFetch();
  const navigate = useNavigate();
  const { token, userRole, name } = useLocalStorage();
  const ref = useRef(true);
  const [openSidebar, setOpenSidebar] = useState(false);

  const initialState = {
    name: "",
    profile_completion: 0,
    connection_count: 0,
    total_investment: 0,
    incoming_requests: 0,
    investors: 0,
    mentors: 0,
    startups: 0,
    vendors: 0,
    recentInvestments: [],
    recentStartups: [],
    barGraphData: [],
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "recent-startups":
        return {
          ...state,
          recentStartups: [...state.recentStartups, ...action.payload],
        };
      case "recent-investments":
        return {
          ...state,
          recentInvestments: [...state.recentInvestments, ...action.payload],
        };
      case "update-platform-stats":
        return {
          ...state,
          startups: action.payload.startups,
          mentors: action.payload.mentors,
          investors: action.payload.investors,
          vendors: action.payload.vendors,
        };
      case "update-user":
        return {
          ...state,
          name: action.payload.name,
          profile_completion: action.payload.profile_completion,
          total_investment: action.payload.total_investment || 0,
          connection_count: action.payload.connection_count,
          incoming_requests: action.payload.incoming_requests || 0,
        };
      case "update-bar-graph":
        return {
          ...state,
          barGraphData: action.payload.barGraphData,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getInvestorData = (data) => {
    dispatch({ type: "recent-startups", payload: data.recent_startup });
    dispatch({ type: "recent-investments", payload: data.agreement_details });
    dispatch({
      type: "update-platform-stats",
      payload: {
        startups: data.startup_count,
        mentors: data.mentor_count,
        investors: data.investor_count,
        vendors: data.vendor_count,
      },
    });
    dispatch({
      type: "update-user",
      payload: {
        name: data.name,
        total_investment: data.total_investment,
        withdrawable_amount: data.wallet_points,
        profile_completion: data.profile_completion,
        connection_count: data.connection_count,
      },
    });
    dispatch({
      type: "update-bar-graph",
      payload: {
        barGraphData: data.industries_count,
      },
    });
  };

  const investorDashboard = useCallback(() => {
    fetchApi(
      {
        url: investorDashboardApi,
        method: "GET",
        headers: {
          "x-token": token,
        },
      },
      (data) => {
        getInvestorData(data);
      }
    );
  }, [investorDashboardApi, fetchApi, token]);

  useEffect(() => {
    if (userRole !== "Sw%aa") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      investorDashboard();
    }
    return () => {
      ref.current = false;
    };
  }, [investorDashboard, userRole, navigate]);

  const ViewProfile = (connectionId, name) => {
    navigate(`/view-startup/${name.split(" ").join("-")}/${connectionId}`);
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-4">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <div className="grid grid-cols-8 gap-3 widget-grid">
              <div className="col-span-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  <WelcomeCard name={name} />
                  <AmountCard
                    userRole={userRole}
                    profile_completion={state.profile_completion}
                  />
                </div>
              </div>
              <div className="col-span-8 lg:col-span-5 space-y-3">
                <div className="grid grid-cols-4 gap-3">
                  <div className="col-span-4 bg-white shadow-md rounded-lg p-5">
                    <h6 className="font-semibold mb-1">Users</h6>
                    <p className="text-gray-600 text-xs font-medium">
                      Platform Summary
                    </p>
                    <div className="grid grid-cols-4 gap-2 mt-5">
                      <div className="col-span-2 md:col-span-1 p-3 rounded-lg bg-[#FFE2E5] space-y-1">
                        <div className="w-[30px] h-[30px] rounded-full bg-[#FA5A7D] p-1 flex items-center justify-center">
                          <RocketIcon fill="#fff" />
                        </div>
                        <p className="font-semibold text-lg">
                          {state.startups}+
                        </p>
                        <p className="text-gray-600 text-sm font-semibold">
                          Startups
                        </p>
                        <Link
                          to="/startups"
                          className="text-[11px] text-blue-700 flex items-center gap-x-1 font-medium"
                        >
                          View Details{" "}
                          <AngleRightIcon
                            width="11px"
                            height="11px"
                            fill="#3b82f6"
                          />
                        </Link>
                      </div>
                      <div className="col-span-2 md:col-span-1 p-3 rounded-lg bg-[#DCFCE7] space-y-1">
                        <div className="w-[30px] h-[30px] rounded-full bg-[#3CD856] p-1 flex items-center justify-center">
                          <UserGraduateIcon fill="#fff" />
                        </div>
                        <p className="font-semibold text-lg">
                          {state.mentors}+
                        </p>
                        <p className="text-gray-600 text-sm font-semibold">
                          Mentors
                        </p>
                        <Link
                          to="/mentors"
                          className="text-[11px] text-blue-700 flex items-center gap-x-1 font-medium"
                        >
                          View Details{" "}
                          <AngleRightIcon
                            width="11px"
                            height="11px"
                            fill="#3b82f6"
                          />
                        </Link>
                      </div>
                      <div className="col-span-2 md:col-span-1 p-3 rounded-lg bg-[#FFF4DE] space-y-1">
                        <div className="w-[30px] h-[30px] rounded-full bg-[#FF947A] p-1 flex items-center justify-center">
                          <UserTieIcon fill="#fff" />
                        </div>
                        <p className="font-semibold text-lg">
                          {state.investors}+
                        </p>
                        <p className="text-gray-600 text-sm font-semibold">
                          Investors
                        </p>
                        <Link
                          to="/investors"
                          className="text-[11px] text-blue-700 flex items-center gap-x-1 font-medium"
                        >
                          View Details{" "}
                          <AngleRightIcon
                            width="11px"
                            height="11px"
                            fill="#3b82f6"
                          />
                        </Link>
                      </div>
                      <div className="col-span-2 md:col-span-1 p-3 rounded-lg bg-[#F3E8FF] space-y-1">
                        <div className="w-[30px] h-[30px] rounded-full bg-[#BF83FF] p-1 flex items-center justify-center">
                          <ShoppingCartIcon fill="#fff" />
                        </div>
                        <p className="font-semibold text-lg">
                          {state.vendors}+
                        </p>
                        <p className="text-gray-600 text-sm font-semibold">
                          Service Providers
                        </p>
                        <Link
                          to="/vendors"
                          className="text-[11px] text-blue-700 flex items-center gap-x-1 font-medium"
                        >
                          View Details{" "}
                          <AngleRightIcon
                            width="11px"
                            height="11px"
                            fill="#3b82f6"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden md:grid grid-cols-6 gap-2 bg-white rounded-lg shadow-md p-5">
                  <h6 className="col-span-6 font-semibold">Profile Summary</h6>
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <LongCard
                      data={{
                        title: "Total Investment",
                        count: state.total_investment,
                      }}
                      img={rupeeIcon}
                      alt="rupee-icon"
                      gradient="primary"
                      width={25}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <LongCard
                      data={{
                        title: "Incoming Requests",
                        count: state.incoming_requests,
                      }}
                      img={addUserIcon}
                      alt="add-user-icon"
                      gradient="success"
                      width={25}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <LongCard
                      data={{
                        title: "Connections",
                        count: state.connection_count,
                      }}
                      img={usersIcon}
                      alt="people-icon"
                      width={25}
                    />
                  </div>
                </div>
                <Carousel className="md:!hidden">
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <LongCard
                      data={{
                        title: "Total Investment",
                        count: state.total_investment,
                      }}
                      img={rupeeIcon}
                      alt="rupee-icon"
                      gradient="primary"
                      width={25}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <LongCard
                      data={{
                        title: "Incoming Requests",
                        count: state.incoming_requests,
                      }}
                      img={addUserIcon}
                      alt="add-user-icon"
                      gradient="success"
                      width={25}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 md:col-span-2">
                    <LongCard
                      data={{
                        title: "Connections",
                        count: state.connection_count,
                      }}
                      img={usersIcon}
                      alt="people-icon"
                      width={25}
                    />
                  </div>
                </Carousel>
              </div>
              <div className="col-span-8 lg:col-span-3 card shadow rounded-md">
                <h5 className="text-lg font-medium">
                  Popular Startup Industries
                </h5>
                <div className="px-0 cstm-svg-pos uppercase">
                  <BarGraph data={state.barGraphData} />
                </div>
              </div>
              <div className="col-span-8 md:col-span-5 py-3">
                <RecentInvestments data={state.recentInvestments} />
              </div>
              <div className="col-span-8 md:col-span-3 space-y-3">
                <div className="flex items-center justify-between">
                  <h5 className="text-lg font-medium">New Startups</h5>
                  <Link
                    to="/startups"
                    className="text-sm flex items-center gap-x-1 text-primary font-medium"
                  >
                    View All <LongRightArrow fill="#17335E" />
                  </Link>
                </div>
                <div className="grid gap-2 card shadow-md rounded-lg py-3">
                  {state.recentStartups && state.recentStartups.length > 0 ? (
                    state.recentStartups.map((startup) => (
                      <RecentStartups
                        key={startup.user_id}
                        data={startup}
                        ViewProfile={ViewProfile}
                        userId={startup.user_id}
                      />
                    ))
                  ) : (
                    <p>No new startups found.</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestorDashBoard;
