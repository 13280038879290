import { Link, useNavigate } from "react-router-dom";
import { Button, Pagination } from "../../components/ui/Button";
import { DeleteIcon, EyeIcon, PenIcon } from "../../icons/icons";
import FavIcon from "../../images/favicon.png";

const MeetingsTable = ({
  data,
  userId,
  handleModals,
  page,
  setPage,
  tableType,
  userRole,
  setViewMeetingDetail,
  handleConfirmationModal,
}) => {
  const navigate = useNavigate();

  let filterData;
  if (tableType === "All") filterData = data;
  else if (tableType === "Accepted") {
    filterData = data.filter((data) => data.status === "1");
  } else if (tableType === "Declined") {
    filterData = data.filter((data) => data.status === "-1");
  } else if (tableType === "Pending") {
    filterData = data.filter((data) => data.status === "0");
  } else if (tableType === "Add Summary") {
    filterData = data.filter((data) => {
      if (data.status === "2") {
        if (userRole === "f@!3A") {
          return data.startup_summary_status === "0";
        } else {
          return data.other_summary_status === "0";
        }
      }
      return false;
    });
  }

  const viewProfile = (name, id, role) => {
    if (role === "f@!3A")
      navigate(`/view-startup/${name.split(" ").join("-")}/${id}`);
    else if (role === "R$7s2")
      navigate(`/view-mentor/${name.split(" ").join("-")}/${id}`);
    else navigate(`/view-investor/${name.split(" ").join("-")}/${id}`);
  };

  return (
    <>
      <div className="overflow-auto w-full">
        <table className="table-auto min-w-max w-full border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">User</th>
              <th className="p-3 font-medium text-sm">Meeting Date</th>
              <th className="p-3 font-medium text-sm">Meeting Time</th>
              <th className="p-3 font-medium text-sm">Title</th>
              <th className="p-3 font-medium text-sm">Type</th>
              <th className="p-3 font-medium text-sm">Status</th>
              <th className="p-3 font-medium text-sm">Action</th>
              <th className="p-3 font-medium text-sm">Details</th>
            </tr>
          </thead>
          <tbody>
            {filterData.length > 0 ? (
              filterData.slice(page * 5 - 5, page * 5).map((meeting) => (
                <tr
                  key={meeting.id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 flex items-center capitalize">
                    <div
                      className="flex items-center cursor-pointer"
                      title="View profile"
                      onClick={() =>
                        viewProfile(
                          userId === meeting.reciever_id
                            ? meeting.sender_name
                            : meeting.reciever_name,
                          userId === meeting.reciever_id
                            ? meeting.sender_id
                            : meeting.reciever_id,
                          userId === meeting.reciever_id
                            ? meeting.sender_role
                            : meeting.reciever_role
                        )
                      }
                    >
                      <img
                        src={
                          userId === meeting.reciever_id
                            ? meeting.sender_logo.length > 0
                              ? meeting.sender_logo
                              : FavIcon
                            : meeting.reciever_logo.length > 0
                            ? meeting.reciever_logo
                            : FavIcon
                        }
                        alt="Logo"
                        className="mx-3 w-[40px] h-[40px] md:w-[50px] md:h-[50px] rounded-full border"
                        loading="lazy"
                      />
                      <div className="ml-2">
                        <p className="font-medium">
                          {userId === meeting.reciever_id
                            ? meeting.sender_name
                            : meeting.reciever_name}
                        </p>
                        <span
                          className={`px-1 text-xs ${
                            userId === meeting.reciever_id
                              ? meeting.sender_role === "f@!3A"
                                ? "blue-badge"
                                : meeting.sender_role === "R$7s2"
                                ? "orange-badge"
                                : meeting.sender_role === "Sw%aa"
                                ? "green-badge"
                                : "indigo-badge"
                              : meeting.reciever_role === "f@!3A"
                              ? "blue-badge"
                              : meeting.reciever_role === "R$7s2"
                              ? "orange-badge"
                              : meeting.reciever_role === "Sw%aa"
                              ? "green-badge"
                              : "indigo-badge"
                          }`}
                        >
                          {userId === meeting.reciever_id
                            ? meeting.sender_role === "f@!3A"
                              ? "Startup"
                              : meeting.sender_role === "R$7s2"
                              ? "Mentor"
                              : meeting.sender_role === "Sw%aa"
                              ? "Investor"
                              : "Service Provider"
                            : meeting.reciever_role === "f@!3A"
                            ? "Startup"
                            : meeting.reciever_role === "R$7s2"
                            ? "Mentor"
                            : meeting.reciever_role === "Sw%aa"
                            ? "Investor"
                            : "Service Provider"}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="p-3 text-xs md:text-sm">{meeting.date}</td>
                  <td className="p-3 text-xs md:text-sm">{meeting.time}</td>
                  <td className="p-3 text-xs md:text-sm">
                    {meeting.title.length < 10
                      ? meeting.title
                      : meeting.title.slice(0, 10).concat("...")}
                  </td>
                  <td className="p-3">
                    <span
                      className={`p-1 text-xs ${
                        userId === meeting.reciever_id
                          ? "green-badge"
                          : "blue-badge"
                      }`}
                    >
                      {userId === meeting.reciever_id ? "Incoming" : "Outgoing"}
                    </span>
                  </td>
                  <td className="p-3">
                    <span
                      className={`p-1 text-xs ${
                        meeting.status === "0"
                          ? "orange-badge"
                          : meeting.status === "1"
                          ? "blue-badge"
                          : meeting.status === "2"
                          ? "green-badge"
                          : "red-badge"
                      }`}
                    >
                      {meeting.status === "0"
                        ? "Pending"
                        : meeting.status === "1"
                        ? "Accepted"
                        : meeting.status === "2"
                        ? "Completed"
                        : "Declined"}
                    </span>
                  </td>
                  <td className="align-middle p-3 text-xs md:text-sm">
                    <div className="flex items-center justify-evenly gap-2 h-full">
                      <span title="View Meeting Details">
                        <EyeIcon
                          className="cursor-pointer fill-green-500 transition-colors ease-in duration-50 hover:fill-green-800"
                          onClick={() =>
                            setViewMeetingDetail({
                              open: true,
                              data: meeting,
                            })
                          }
                        />
                      </span>
                      {meeting.status === "0" && (
                        <span title="Edit Meeting Details">
                          <PenIcon
                            className="cursor-pointer fill-blue-500 transition-colors ease-in duration-50 hover:fill-blue-800"
                            onClick={() => handleModals("meeting", meeting)}
                          />
                        </span>
                      )}
                      {userId === meeting.sender_id &&
                        meeting.status === "0" && (
                          <span title="Delete Meeting">
                            <DeleteIcon
                              className="cursor-pointer fill-red-500 transition-colors ease-in duration-50 hover:fill-red-800"
                              onClick={() =>
                                handleConfirmationModal(
                                  meeting.id,
                                  "cancel",
                                  userId === meeting.reciever_id
                                    ? meeting.sender_id
                                    : meeting.reciever_id
                                )
                              }
                            />
                          </span>
                        )}
                    </div>
                  </td>
                  <td className="p-3">
                    <div className="flex items-center justify-around">
                      {userId === meeting.reciever_id ? (
                        meeting.status === "0" ? (
                          <span>
                            <Button
                              onClick={() =>
                                handleConfirmationModal(
                                  meeting.id,
                                  "accept",
                                  userId === meeting.reciever_id
                                    ? meeting.sender_id
                                    : meeting.reciever_id,
                                  meeting.sender_role,
                                  meeting.reciever_role
                                )
                              }
                              className="py-1 px-2 mx-1 text-xs"
                              bgColor="bg-green-600"
                            >
                              Accept
                            </Button>
                            <Button
                              onClick={() =>
                                handleConfirmationModal(
                                  meeting.id,
                                  "reject",
                                  userId === meeting.reciever_id
                                    ? meeting.sender_id
                                    : meeting.reciever_id,
                                  meeting.sender_role,
                                  meeting.reciever_role
                                )
                              }
                              className="py-1 px-2 mx-1 text-xs"
                              bgColor="bg-secondary"
                            >
                              Decline
                            </Button>
                          </span>
                        ) : (
                          meeting.status === "1" &&
                          meeting.signature !== "" && (
                            <Link
                              to={meeting.signature}
                              target="_blank"
                              className="bg-green-600 text-white py-1 px-2 mx-1 text-xs rounded-md"
                            >
                              Join
                            </Link>
                          )
                        )
                      ) : (
                        meeting.status === "1" &&
                        meeting.signature !== "" && (
                          <Link
                            to={meeting.signature}
                            target="_blank"
                            className="bg-green-600 text-white py-1 px-2 mx-1 text-xs rounded-md"
                          >
                            Join
                          </Link>
                        )
                      )}
                      {meeting.status === "2" &&
                        ((
                          userRole === "f@!3A"
                            ? meeting.startup_summary_status === "0"
                            : meeting.other_summary_status === "0"
                        ) ? (
                          <Button
                            className="py-1 px-2 mx-1 text-xs"
                            bgColor="bg-orange-600"
                            onClick={() =>
                              navigate(`/meeting-summary/${meeting.id}`)
                            }
                          >
                            Add Summary
                          </Button>
                        ) : (
                          <Button
                            className="py-1 px-2 mx-1 text-xs"
                            onClick={() => handleModals("summary", meeting.id)}
                          >
                            View Summary
                          </Button>
                        ))}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={8} className="p-3 text-xs md:text-sm">
                  No Meeting Scheduled
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterData.length > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          totalDataCount={filterData.length}
        />
      )}
    </>
  );
};

export default MeetingsTable;
